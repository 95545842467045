import axios from 'axios';
import { Flex } from 'reflexbox';
import { FormButton, FormElement, FormInput, FormTextArea, FormWrapper } from './';
import React from 'react';
import { FormattedMessage as Tr } from 'react-intl';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Form = () => {
  const [formValues, setFormValues] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const { name, email, phone, message } = formValues;
  const [sent, setSent] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = (e) => {
    setSending(true);
    axios
      .post('/', encode({ 'form-name': 'contact-dokwest29', ...formValues }), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(() => setSent(true))
      .catch(() => setError(true));
    setSending(false);
    e.preventDefault();
  };

  const handleChange = (e) => setFormValues({ ...formValues, [e.target.name]: e.target.value });

  return (
    <Flex justifyContent={'center'}>
      <FormWrapper p={[3, 3, 4]} pt={[3]}>
        <form name="contact-dokwest29" data-netlify="true">
          <input type="hidden" name="form-name" value="contact-dokwest29" />
          <FormElement>
            <Tr id="contact.name" />
            <FormInput type="text" name="name" value={name} onChange={handleChange} />
          </FormElement>
          <FormElement>
            <Tr id="contact.email" />
            <FormInput type="email" name="email" value={email} onChange={handleChange} />
          </FormElement>
          <FormElement>
            <Tr id="contact.phone" />
            <FormInput type="tel" name="phone" value={phone} onChange={handleChange} />
          </FormElement>
          <FormElement>
            <Tr id="contact.message" />
            <FormTextArea rows="4" name="message" value={message} onChange={handleChange} />
          </FormElement>
          <Flex justifyContent="flex-end">
            <FormButton disabled={sending} isSending={sending} onClick={handleSubmit}>
              <Tr id="contact.send" />
            </FormButton>
          </Flex>
          <Flex justifyContent="flex-end">{sent && <small>Bericht verzonden.</small>}</Flex>
          <Flex justifyContent="flex-end">{error && <small>Er liep iets mis, probeer nog eens.</small>}</Flex>
        </form>
      </FormWrapper>
    </Flex>
  );
};

export default Form;
