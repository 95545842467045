import styled from '@emotion/styled';
import React from 'react';
import { Box } from 'reflexbox';
import Company from '../../components/Company';
import paletteTheme from '../../config/theme/paletteTheme';

const CompanyBox = ({ image, text, link, naam, email, telefoon }) => {
  return (
    <Box width={[1, 1, 1 / 2]} p={[2, 3, 4]}>
      <Company image={image} link={link}>
        {text.map((txt) => (
          <p key={txt}>{txt}</p>
        ))}
      </Company>
      <Info>
        {naam && <p>{naam}</p>}
        {email && (
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
        )}
        {telefoon && (
          <p>
            <a href={`tel:${telefoon}`}>{telefoon}</a>
          </p>
        )}
      </Info>
    </Box>
  );
};

const Info = styled.div`
  padding: 5px 15px;

  a {
    color: #333333;
    text-decoration: underline;

    &:hover {
      color: ${paletteTheme.secondary.shade4};
    }
  }
`;

export default CompanyBox;
