import React from 'react';
import { Flex, Box } from 'reflexbox';
import { Form } from './contactForm';
import styled from '../../config/theme';
import { media } from '../../config/theme/utils';
import { css } from 'styled-components';
import { FormattedMessage as Tr } from 'react-intl';

const Contact = () => (
  <ContactPageWrapper>
    <h2>
      <Tr id="contact.title" />
    </h2>
    <Flex flexWrap={'wrap'}>
      <Box width={[1, 1 / 2]}>
        <TextWrapper p={3}>
          <h3>
            <Tr id="contact.horen" />
          </h3>
          <p>
            <Tr id="contact.neemContact" />
          </p>
          <br />
          <h5>
            <Tr id="contact.afspraak" />
          </h5>
          <p>Dokwest29 - Asona Belgium</p>
          <p>Verbindingsdok 29</p>
          <p>2000 Antwerpen</p>
          <PWithMargin>Tel: 0032.3.312.58.47</PWithMargin>
          <p>
            <a href="mailto:hello@dokwest29.be">hello@dokwest29.be</a>
          </p>
          <p>
            <InstagramLink target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/dokwest29/">
              bekijk onze instagram
            </InstagramLink>
          </p>
        </TextWrapper>
      </Box>
      <Box width={[1, 1 / 2]} p={3}>
        <Form />
      </Box>
    </Flex>
  </ContactPageWrapper>
);

export default Contact;

const ContactPageWrapper = styled(Flex)`
  padding-top: 100px;
  z-index: 2;

  ${media.mobile &&
  css`
    flex-flow: column;
  `}
`;

const InstagramLink = styled.a`
  color: #000;
`;

const PWithMargin = styled.p`
  margin-top: 10px;
`;

const TextWrapper = styled(Box)`
  h3 {
    padding: 0;
    background-color: transparent;
    margin: 30px 0;
  }
`;
