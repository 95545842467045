import React from 'react';
import styled from 'styled-components';

const MenuLine = () => (
  <MenuLineWrapper>
    <DownArrow>↓</DownArrow>
  </MenuLineWrapper>
);

export default MenuLine;

const MenuLineWrapper = styled.div`
  border-right: 1px solid #e8e8e8;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 90px;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const DownArrow = styled.p`
  color: #ffb200;
  font-size: 3em;
  font-weight: 200;
  margin-left: 20px;
  position: fixed;
  top: 320px;
`;
