import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Nl from '../assets/icons/be.svg';
import Fr from '../assets/icons/fr.svg';

const languageOptions = [
  {
    value: 'nl',
    label: (
      <>
        <img src={Nl} alt="nl" width="30px" />
        <span className="langText">NL</span>
      </>
    ),
  },
  {
    value: 'fr',
    label: (
      <>
        <img src={Fr} alt="fr" width="30px" />
        <span className="langText">FR</span>
      </>
    ),
  },
];

const LanguageSwitcher = () => {
  const handleChangeLang = (chosenLang) => {
    changeLocale(chosenLang.value);
    console.log('handleChange triggered');
  };

  return (
    <React.Fragment>
      <IntlContextConsumer>
        {(currentLocale) => (
          <StyledSelect
            options={languageOptions}
            value={languageOptions.filter((languageOption) => languageOption.value === currentLocale.language)}
            onChange={handleChangeLang}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={customStyles}
          />
        )}
      </IntlContextConsumer>
    </React.Fragment>
  );
};

export default LanguageSwitcher;

const customStyles = {
  option: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  control: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
};

const StyledSelect = styled(Select)`
  border-radius: 6px;

  color: black;
  z-index: 3;
  font-size: 1rem;
  max-height: 50px;
  width: 102px;
  img {
    border-radius: 50%;
    padding: 6px;
    vertical-align: middle;
  }
`;
