import React from 'react';
import { FormattedMessage as Tr } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';

const Hero = () => (
  <HeroWrapper>
    <Flex flexWrap="wrap">
      <Box p={3} width={[1, 1 / 2]}>
        <Fade left>
          <h1>
            <Tr id="hero.title" />
          </h1>
        </Fade>
      </Box>
      <Box p={3} width={[1, 1 / 2]}>
        <Flex p={3}>
          <Flex flexDirection="column" pr={3}>
            <Fade>
              <Stat>45</Stat>
              <p>
                <Tr id="hero.ervaring" />
              </p>
            </Fade>
          </Flex>
          <Flex flexDirection="column" pr={3}>
            <Fade>
              <Stat>9</Stat>
              <p>
                <Tr id="hero.partners" />
              </p>
            </Fade>
          </Flex>
        </Flex>
        <Fade bottom>
          <Box p={3}>
            <Tr id="hero.uitleg" />
          </Box>
        </Fade>
      </Box>
    </Flex>
  </HeroWrapper>
);

export default Hero;

const HeroWrapper = styled.div`
  margin-top: 50px;
`;

const Stat = styled.p`
  font-size: 2em;
  color: #ffb200;
  font-weight: 700;
`;
