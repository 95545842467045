import styled from '../../../config/theme';

const FormButton = styled.button`
  background-color: ${(props) => props.theme.palette.secondary.shade4};
  border-radius: 4px;
  border: none;
  color: white;
  font-family: inherit;
  font-size: 0.9em;
  font-weight: 700;
  outline: none;
  padding: 15px 25px;
  transition: all 0.23s ease-in-out;

  &:hover {
    box-shadow: 0 0 12px 0 rgba(255, 178, 0, 0.3);
    cursor: pointer;
    transition: all 0.23s ease-in-out;
  }
`;

export default FormButton;
