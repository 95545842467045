import styled from '../../../config/theme';

const FormInput = styled.input`
  border-radius: 4px;
  border: 0px;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.08);
  outline: none;
  padding: 10px 15px;
  width: 100%;
  transition: all 0.24s ease-in-out;

  &:hover {
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.08);
    transition: all 0.24s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 12px 2px rgba(255, 178, 0, 0.22);
    transition: all 0.24s ease-in-out;
  }
`;

export default FormInput;
