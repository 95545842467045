import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import ExternalLink from '../assets/images/external-link-white.svg';

const Company = ({ image, color, link, children }) => (
  <a href={link} rel="noreferrer noopener" target="_blank">
    <CompanyWrapper>
      <ImageHelper>
        <img className="image" src={image} />
      </ImageHelper>
      <Flex alignItems="flex-end">
        <DescriptionWrapper color={color}>{children}</DescriptionWrapper>
        <StyledSpan className="link">
          <StyledExternalLinkImg src={ExternalLink} alt="external link" />
        </StyledSpan>
      </Flex>
    </CompanyWrapper>
  </a>
);

export default Company;

const ImageHelper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  height: 180px;
  padding: 15px 35px;
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.35s ease-in-out;

  .link {
    opacity: 0;
    position: relative;
    right: 0;
    transition: all 0.35s ease-in-out;
  }
  .image {
    max-height: 120px;
    filter: grayscale(1);
    transition: all 0.35s ease-in-out;
    max-width: 100%;
  }

  &:hover {
    box-shadow: 1px 11px 16px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.35s ease-in-out;
    .link {
      opacity: 1;
      transition: all 0.35s ease-in-out;
    }
    .image {
      filter: grayscale(0);
      transition: all 0.35s ease-in-out;
    }
  }
`;

const DescriptionWrapper = styled.div`
  text-align: left;
  padding: 15px 5px 10px 20px;
  width: 100%;

  p {
    color: ${(props) => props.color || '#292929'};
    font-size: 1.2em;
    letter-spacing: 2px;
    margin-bottom: 0;
    text-align: left;
  }
`;

const StyledSpan = styled.span`
  background-color: ${(props) => props.theme.palette.secondary.shade4};
  border-radius: 4px 0 4px 0;
  cursor: pointer;
  font-size: 1.5em;
  margin-left: 15px;
  padding: 0 10px;
`;

const StyledExternalLinkImg = styled.img`
  height: 18px;
`;
