import React from 'react';
import styled from 'styled-components';

const Footer = () => (
  <FooterWrapper>
    made with{' '}
    <Emoji role="emoji" aria-label="flexed biceps">
      💪
    </Emoji>
    by
    <Styleda href="https://upshift.be" target="_blank">
      upshift
    </Styleda>
  </FooterWrapper>
);

export default Footer;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const Styleda = styled.a`
  margin-left: 5px;
`;

const Emoji = styled.span`
  margin: 0 5px;
`;
