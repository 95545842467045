import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { FormattedMessage as Tr } from 'react-intl';
import { Flex } from 'reflexbox';
import styled, { css } from 'styled-components';
import Asona from '../../assets/images/asona-nobg.png';
import DeSomer from '../../assets/images/toondesomer.png';
import FloorCouture from '../../assets/images/floorcouture.png';
import Triplaco from '../../assets/images/PrintAcoustics.png';
import Sika from '../../assets/images/sika.png';
import StoopenMeeus from '../../assets/images/stoopenmeeus.png';
import Xinnix from '../../assets/images/xinnix.png';
import Repro from '../../assets/images/repro.png';
import Zumi from '../../assets/images/zumi-nobg.png';
import { media } from '../../config/theme/utils';
import CompanyBox from './CompanyBox';

const Companies = () => {
  const intl = useIntl();
  const fM = intl.formatMessage;

  return (
    <PlacementHelper>
      <h2>
        <Tr id="companies.partners" />
      </h2>

      <CompanyWrapper>
        <Flex flexWrap="wrap">
          <CompanyBox
            image={Asona}
            text={[fM({ id: 'companies.spuitpleisters' }), fM({ id: 'companies.geluidsabsorptie' })]}
            link="https://asona.com/"
            naam="Erin Huysmans"
            email="eh@asona.be"
            telefoon="+32 470 08 66 26"
          />
          <CompanyBox
            image={DeSomer}
            text={[fM({ id: 'companies.kantoorinrichting' }), fM({ id: 'companies.designmeubilair' })]}
            link="https://www.desomer.be"
            naam="Michaël Swalens"
            email="info@desomer.be"
            telefoon="+32 3 633 33 33"
          />
        </Flex>
        <Flex flexWrap="wrap">
          <CompanyBox
            image={FloorCouture}
            text={[fM({ id: 'companies.gietvloeren' })]}
            link="https://floorcouture.be/"
            naam="Patrick Tjoens"
            email="patrick@floorcouture.be"
            telefoon="+32 475 66 09 82"
          />
          <CompanyBox
            image={Sika}
            text={[fM({ id: 'companies.vloeren' }), fM({ id: 'companies.gietvloeren' })]}
            link="https://bel.sika.com/"
            naam=""
            email=""
            telefoon="+32 9 381 65 00"
          />
        </Flex>
        <Flex flexWrap="wrap">
          <CompanyBox
            image={StoopenMeeus}
            text={[fM({ id: 'companies.pigmenten' }), fM({ id: 'companies.verftechniek' })]}
            link="http://www.stoopen-meeus.com/"
            naam="An Peeters"
            email="an.peeters@stoopen-meeus.com"
            telefoon="+32 485 48 01 71"
          />
          <CompanyBox
            image={Triplaco}
            text={[fM({ id: 'companies.geluidsabsorptie' })]}
            link="https://www.triplaco.be/nl-be/"
            naam="Ruben Lannoo"
            email="r.lannoo@triplaco.be"
            telefoon="+32 56 26 14 40"
          />
        </Flex>

        <Flex flexWrap="wrap">
          <CompanyBox
            image={Xinnix}
            text={[fM({ id: 'companies.onzichtbareDeur' }), fM({ id: 'companies.isolerendeDeur' })]}
            link="https://www.xinnixdoorsystems.be/"
            naam=""
            email=""
            telefoon=""
          />
          <CompanyBox
            image={Zumi}
            text={[fM({ id: 'companies.verlichting' }), fM({ id: 'companies.akoestischOpl' })]}
            link="https://www.zumi.be/en/"
            naam=""
            email=""
            telefoon=""
          />
        </Flex>
        <Flex flexWrap="wrap">
          <CompanyBox
            image={Repro}
            text={[fM({ id: 'companies.kantoorbenodigdheden' })]}
            link="https://www.repro.be"
            naam="Jan Leunis"
            email="info@repro.be"
            telefoon="+32 3 641 97 95"
          />
        </Flex>
      </CompanyWrapper>
    </PlacementHelper>
  );
};

export default Companies;

const PlacementHelper = styled.div`
  margin-top: 200px;
  ${media.tabletAndBelow &&
  css`
    margin-top: 60px;
  `}
`;

const CompanyWrapper = styled.div`
  @media screen and (min-width: 1000px) {
    margin-left: 100px;
  }
`;
