import React from 'react';
import Hero from './Hero';
import Aankondiging from './Aankondiging';

const Home = () => (
  <React.Fragment>
    <Hero />
    <Aankondiging />
  </React.Fragment>
);

export default Home;
