import React from 'react';
import { FormattedMessage as Tr } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import asona1 from '../../assets/images/asona1.jpg';
import asona10 from '../../assets/images/asona10.jpg';
import asona2 from '../../assets/images/asona2.jpg';
import asona3 from '../../assets/images/asona3.jpg';
import asona4 from '../../assets/images/asona4.jpg';
import asona5 from '../../assets/images/asona5.jpg';
import asona6 from '../../assets/images/asona6.jpg';
import asona7 from '../../assets/images/asona7.jpg';
import asona8 from '../../assets/images/asona8.jpg';
import asona9 from '../../assets/images/asona9.jpg';

const About = () => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const imageArray = [asona1, asona2, asona3, asona4, asona5, asona6, asona7, asona8, asona9];

  React.useEffect(() => {
    setTimeout(() => {
      if (imageIndex < 8) {
        setImageIndex(imageIndex + 1);
      } else {
        setImageIndex(0);
      }
    }, 3500);
  }, [imageIndex]);

  return (
    <AboutWrapper>
      <Flex justifyContent="flex-end" width={1} mb={-4} minHeight={'500px'}>
        <CompanyImg src={imageArray[imageIndex]} alt="dokwest29 antwerpen" />
      </Flex>
      <h2 id="about">
        <Tr id="about.overOns" />
      </h2>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]} p={3} alignSelf="center">
          <Fade left>
            <h3>
              <Tr id="about.samenKomt" />
            </h3>
            <Styledp>
              <Tr id="about.producten" />
            </Styledp>
            <Styledp>
              <Tr id="about.focus" />
            </Styledp>
            <Styledp>
              <Tr id="about.organiseren" />
            </Styledp>
          </Fade>
        </Box>
        <Box width={[1, 1 / 2]} p={3} mt={[0, -5]}>
          <CompanyImg2 src={asona10} alt="dokwest29 antwerpen" />
        </Box>
      </Flex>
    </AboutWrapper>
  );
};

export default About;

const AboutWrapper = styled.div`
  margin-top: 50px;
`;

const CompanyImg = styled.img`
  max-width: 70%;
  margin-left: 200px;
  border-radius: 4px;
  max-height: 500px;
  height: 100%;
`;

const CompanyImg2 = styled.img`
  max-width: 100%;
  border-radius: 4px;
`;

const Styledp = styled.p`
  padding-bottom: 10px;
`;
