import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/dokwest.jpg';
import GlobalCollection from '../config/GlobalCollection';
import About from '../modules/about';
import Company from '../modules/companies/';
import Contact from '../modules/contact';
import Footer from '../modules/footer';
import Home from '../modules/home';
import MenuLine from '../modules/menuLine';
import LanguageSwitcher from '../components/LanguageSwitcher';

const Index = () => (
  <StretchHelper>
    <GlobalCollection>
      <StyledHeader>
        <TopNav>
          <LogoImg src={Logo} alt="logo" />
        </TopNav>
      </StyledHeader>

      <Container>
        <MenuLine />
        <TransparencyHelper>
          <Home />
          <About />
          <Company />
          <Contact />
        </TransparencyHelper>
      </Container>
      <Footer />
      <Helper>
        <LanguageSwitcher />
      </Helper>
    </GlobalCollection>
  </StretchHelper>
);

export default Index;

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
`;

const TransparencyHelper = styled.div`
  position: relative;
  z-index: 2;
`;

const StretchHelper = styled.div`
  position: relative;
`;

const StyledHeader = styled.div`
  display: flex;
  position: sticky;
  background-color: ${(props) => props.theme.palette.primary.shade4};
  color: #ffffff;
  justify-content: center;
  z-index: 2;
`;

const TopNav = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  max-width: 1200px;
  background-color: ${(props) => props.theme.palette.primary.shade4};
  color: #ffffff;
`;

const LogoImg = styled.img`
  height: 80px;
`;

const Helper = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 3;
`;
