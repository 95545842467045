import React from 'react';
import { FormattedMessage as Tr } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';

const Aankondiging = () => (
  <HeroWrapper>
    <Flex flexDirection="column" flexWrap="wrap">
      <Box p={3} width={[1]}>
        <Fade left>
          <h3>
            <Tr id="aankondiging.title" />
          </h3>
        </Fade>
      </Box>
      <Box p={3} width={[1]}>
        <Fade bottom>
          <Paragraph p={3}>
            <Tr id="aankondiging.text" />
          </Paragraph>
          <Paragraph p={3}>
            <Tr id="aankondiging.link" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfq94IW1-D7V3RuS8F8P0SOlbJ9g8dkV61R3w9cD-gBo8RQag/viewform?usp=pp_url"
            >
              Formulier
            </a>
          </Paragraph>
        </Fade>
      </Box>
    </Flex>
  </HeroWrapper>
);

export default Aankondiging;

const HeroWrapper = styled.div`
  margin-top: 50px;
  border-radius: 5px;
  background: rgb(255, 178, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 178, 0, 0.7) 0%,
    rgba(255, 178, 0, 0.4) 50%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const Paragraph = styled(Box)`
  max-width: 800px;
  line-height: 180%;
  white-space: pre-line;

  > a {
    font-weight: 700;
  }
`;
